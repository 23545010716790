<template>
  <vx-card>
    <div class="vx-col w-full">
      <span>
        <b>{{ action }} {{ title }}</b>
      </span>
      <div class="vx-row mb-6">
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full"
            label="Code"
            v-validate="'required'"
            name="Code"
            disabled
            v-model="data.code"
          />
          <span class="text-danger text-sm" v-show="errors.has('Code')">{{
            errors.first("Code")
          }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Territory</label>
          <multiselect
            class="selectExample"
            v-model="territory"
            :options="optionTerritory"
            :show-labels="false"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="id"
            label="name"
            name="territory"
            v-validate="'required'"
            :disabled="false"
          >
            <template slot="singleLabel" slot-scope="dt">
              <span class="option__desc">
                <span class="option__title">{{ dt.option.name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="dt">
              <div class="option__desc">
                <span class="option__title">{{ dt.option.name }}</span>
              </div>
            </template>
          </multiselect>
          <span class="text-danger text-sm" v-show="errors.has('Territory')">{{
            errors.first("Territory")
          }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Customer Group 1</label>
          <div class="flex">
            <multiselect
              class="selectExample"
              v-model="customerGroup1"
              :options="optionCustomerGroups1"
              :multiple="false"
              :show-labels="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="160"
              @search-change="handleSearchCustomerGroup1"
              placeholder=" Type to search"
              :disabled="false"
              label="name"
              :custom-label="customLabelOptionCustomerGroup"
            />
            <!-- button reset -->
            <vs-button
              class="ml-2 btn-xs"
              type="border"
              color="danger"
              v-on:click="handleResetField('customerGroup1')"
              >Reset</vs-button
            >
          </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Customer Group 2</label>
          <div class="flex">
            <multiselect
              class="selectExample"
              v-model="customerGroup2"
              :options="optionCustomerGroups2"
              :show-labels="false"
              :allow-empty="false"
              :multiple="false"
              :group-select="false"
              :max-height="160"
              @search-change="handleSearchCustomerGroup2"
              placeholder=" Type to search"
              :disabled="false"
              label="name"
              :custom-label="customLabelOptionCustomerGroup"
            />
            <vs-button
              class="ml-2 btn-xs"
              type="border"
              color="danger"
              v-on:click="handleResetField('customerGroup2')"
              >Reset</vs-button
            >
          </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Customer Group 3</label>
          <div class="flex">
            <multiselect
              class="selectExample"
              v-model="customerGroup3"
              :options="optionCustomerGroups3"
              :multiple="false"
              :show-labels="false"
              :group-select="false"
              :max-height="160"
              @search-change="handleSearchCustomerGroup3"
              placeholder=" Type to search"
              :disabled="false"
              label="name"
              :custom-label="customLabelOptionCustomerGroup"
            />
            <vs-button
              class="ml-2 btn-xs"
              type="border"
              color="danger"
              v-on:click="handleResetField('customerGroup3')"
              >Reset</vs-button
            >
          </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <!-- radio button -->
          <label class="vs-input--label">Status</label>
          <br />
          <br />
          <vs-radio v-model="data.status" vs-value="1">Active</vs-radio>
          &nbsp;&nbsp;&nbsp;
          <vs-radio v-model="data.status" vs-value="0">InActive</vs-radio>
          <span class="text-danger text-sm" v-show="errors.has('Status')">{{
            errors.first("Status")
          }}</span>
        </div>
      </div>

      <!-- list select flyer for flyer allocation -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <h4>Data Flyers</h4>
          <table class="table table-striped">
            <thead class="">
              <tr>
                <th scope="col">Sequence</th>
                <th scope="col">Flyer ID</th>
                <th scope="col">Flyer Description</th>
                <th scope="col">Valid From</th>
                <th scope="col">Valid To</th>
                <th scope="col">Image</th>
                <th scope="col">&nbsp;</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <draggable :list="data.flyers" tag="tbody">
              <tr v-for="(item, index) in data.flyers" :key="index">
                <td>
                  {{ index + 1 }}
                </td>
                <td>
                  <multiselect
                    class="selectExample"
                    v-model="item.selected_flyer"
                    :options="optionFlyer"
                    :multiple="false"
                    :group-select="false"
                    :max-height="160"
                    :allow-empty="false"
                    :show-labels="false"
                    :limit="4"
                    placeholder=" Type to search"
                    :disabled="false"
                    @select="onSelectFlyer($event, index)"
                    @search-change="searchSelectFlyer"
                    :custom-label="selectFlyerLabel"
                  />
                </td>
                <td v-html="item.description"/>
                <td>{{ item.valid_from }}</td>
                <td>{{ item.valid_to }}</td>
                <td><img :src="item.image" alt="" class="w-64" /></td>
                <td class="td vs-table--td" style="text-align: center">
                  <arrow-up-down-bold :size="15" />
                </td>
                <td>
                  <vs-button
                    v-if="data.flyers.length > 1"
                    @click.stop="removeRow(index)"
                    size="small"
                    color="danger"
                    icon-pack="feather"
                    icon="icon-trash"
                    style="margin-right: 5px"
                    title="Remove Row"
                  />

                  <vs-button
                    v-if="index == data.flyers.length - 1"
                    @click.stop="addRow()"
                    size="small"
                    color="success"
                    icon-pack="feather"
                    icon="icon-plus"
                    title="Add Row"
                  />
                </td>
              </tr>
            </draggable>
          </table>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
          <vs-button
            class="ml-4 mt-2"
            type="border"
            color="danger"
            v-on:click="handleClose"
            >Close</vs-button
          >
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import ArrowUpDownBold from "vue-material-design-icons/ArrowUpDownBold.vue";
import moment from "moment";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
    ArrowUpDownBold,
  },
  data() {
    return {
      action: this.$route.query.action,
      id: this.$route.query.id,
      title: "Flyer Allocation",
      isAddedFirst: true,
      data: {
        code: this.$route.query.id == null ? this.generateCode() : "",
        order: 1,
        territory_id: null,
        name: "",
        status: true,
        flyers: [
          {
            sequence: "1",
            // id: null,
            valid_from: null,
            valid_to: null,
            image: null,
            description: null,
          },
        ],
      },
      submitted: false,

      // select
      territory: null,
      optionTerritory: [],
      flyers: [],
      optionFlyer: [],
      customerGroup1: {
          id: 0,
          name: "All",
          code:"All"
        },
      customerGroup2: {
          id: 0,
          name: "All",
          code:"All"
        },
      customerGroup3: {
          id: 0,
          name: "All",
          code:"All"
        },
      optionCustomerGroups1: [],
      optionCustomerGroups2: [],
      optionCustomerGroups3: [],
    };
  },
  filters: {
    // string to int
    toInt(value) {
      return parseInt(value);
    },
  },
  methods: {
    handleResetField(key) {
      this[key] = {
          id: 0,
          name: "All",
          code:"All"
        };
    },
    handleRemoveFlyerSelect(row, index) {
      // change to
      this.data.flyers.splice(index, 1);
    },
    customLabelOptionCustomerGroup({ name, code }) {
      return `${name} - ${code}`;
    },
    selectFlyerLabel({ code }) {
      return `${code}`;
    },
    async searchSelectFlyer(query, id) {
      this.$vs.loading();
      let results = await this.$http.get(
        `/api/sfa/v1/flyers?search=${query}&length=10`
      );
      if (results.code === 200 && results.data.records != null) {
        this.optionFlyer = results.data.records;
      }

      this.$vs.loading.close();
    },
    async getData(id) {
      let results = await this.$http.get(`/api/sfa/v1/flyer-allocations/${id}`);

      if (results.code === 200) {
        let data = results.data;
        this.data.id = data.id;
        this.data.code = data.code;
        this.data.status = data.status ? 1 : 0;
        this.data.territory_id = data.territory_id;

        this.getFlyerByID(data.territory_id);

        // fetch customer group
        if (data.customer_group1 != null) {
          let temp = null;
          if (data.customer_group1 == 0) {
            this.customerGroup1 = {
              id: 0,
              name: "All",
              code: "ALL",
            };
          } else {
            temp = await this.$http.get(
              `/api/sfa/v1/customer-group/${data.customer_group1}`
            );
            this.customerGroup1 = temp.data;
          }
        }
        if (data.customer_group2 != null) {
          let temp = null;
          if (data.customer_group2 == 0) {
            this.customerGroup2 = {
              id: 0,
              name: "All",
              code: "ALL",
            };
          } else {
            temp = await this.$http.get(
              `/api/sfa/v1/customer-group/${data.customer_group1}`
            );

            this.customerGroup2 = temp.data;
          }
        }

        if (data.customer_group3 != null) {
          let temp = null;
          if (data.customer_group3 == 0) {
            this.customerGroup3 = {
              id: 0,
              name: "All",
              code: "ALL",
            };
          } else {
            temp = await this.$http.get(
              `/api/sfa/v1/customer-group/${data.customer_group3}`
            );

            this.customerGroup3 = temp.data;
          }
        }

        // remove first row
        if (this.id != null) {
          this.data.flyers.splice(0, 1);
        }
        for (let i = 0; i < data.flyers.length; i++) {
          this.data.flyers.push({
            sequence: data.flyers[i].sequence,
            id: data.flyers[i].id,
            selected_flyer: {
              id: data.flyers[i].id,
              name: data.flyers[i].description,
              code: data.flyers[i].code,
            },
            description: data.flyers[i].description,
            valid_from: moment(data.flyers[i].valid_from).format("DD MMM YYYY"),
            valid_to: moment(data.flyers[i].valid_to).format("DD MMM YYYY"),
            image: data.flyers[i].image_url,
          });
        }
      }
    },
    doSort() {
      this.data.flyers.sort((a, b) => {
        return a.sequence - b.sequence;
      });
    },
    addRow() {
      let count = this.data.flyers.length + 1;
      // to string
      count = count.toString();
      this.data.flyers.push({
        sequence: count,
        id: null,
        selected_flyer: null,
        description: "",
        valid_from: "",
        valid_to: "",
        image: "",
      });
    },
    removeRow(index) {
      this.data.flyers.splice(index, 1);
    },
    onSelectFlyer(data, index) {
      this.data.flyers[index].id = data.id;
      this.data.flyers[index].description = data.description;
      this.data.flyers[index].valid_from = moment(data.valid_from).format(
        "DD MMM YYYY"
      );
      this.data.flyers[index].valid_to = moment(data.valid_to).format(
        "DD MMM YYYY"
      );
      this.data.flyers[index].image = data.image_url;
    },
    handleSubmit() {
      if (this.territory == null && this.id == null) {
        this.$vs.notify({
          title: "Error",
          text: "Please select territory",
          color: "danger",
          position: "top-right",
        });
        return;
      }

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.postData();
        }
      });
    },
    paramData() {
      const status = this.data.status == 1 ? true : false;

      let data = {
        code: this.data.code,
        status: status,
        territory_id: this.territory.id,
        flyers: this.data.flyers.map((item, index) => {
          return {
            id: item.id,
            sequence: (index + 1).toString(),
          };
        }),
      };

      if (this.customerGroup1 != null) {
        data.customer_group1 = this.customerGroup1.id;
      }

      if (this.customerGroup2 != null) {
        data.customer_group2 = this.customerGroup2.id;
      }

      if (this.customerGroup3 != null) {
        data.customer_group3 = this.customerGroup3.id;
      }

      if (this.id != null) {
        data.id = parseInt(this.id);
      }

      return data;
    },
    postData() {
      this.submitted = true;
      this.$http
        .post("/api/sfa/v1/flyer-allocations", this.paramData(), {
          "Content-Type": "application/json",
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New " + this.title + " Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      // back to list
      this.$router.push({ name: "flyer" });
    },
    getOptionTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/territory/personal-territories", {
          params: {
            personal_id: this.$store.state.user.currentUser.personal_id,
            company_id: this.$store.state.user.currentUser.company_id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionTerritory = resp.data;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Territory option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getFlyerByID(id) {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/territory", {
          params: {
            id: id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records != null) {
              this.territory = resp.data.records[0];
            }
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Flyer Allocation",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionFlyers(query) {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/flyers", {
          params: {
            length: 10,
            search: query,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionFlyer = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Flyers option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    async fetchCustomerGroup(params) {
      let result = await this.$http.get("/api/sfa/v1/customer-group", {
        params: params,
      });

      return result;
    },
    async handleSearchCustomerGroup1(query, id) {
      let optionCustomerGroups = await this.fetchCustomerGroup({
        search: query,
        length: 10,
        company_id: this.$store.state.user.currentUser.company_id,
        level: 1,
      });
      if (optionCustomerGroups.data.records != null) {
        // add all option
        optionCustomerGroups.data.records.unshift({
          id: 0,
          name: "All",
        });
        this.optionCustomerGroups1 = optionCustomerGroups.data.records;
      } else {
        this.optionCustomerGroups1 = [];
      }
    },
    async handleSearchCustomerGroup2(query, id) {
      let optionCustomerGroups = await this.fetchCustomerGroup({
        search: query,
        length: 10,
        company_id: this.$store.state.user.currentUser.company_id,
        level: 2,
      });
      if (optionCustomerGroups.data.records != null) {
        optionCustomerGroups.data.records.unshift({
          id: 0,
          name: "All",
        });
        this.optionCustomerGroups2 = optionCustomerGroups.data.records;
      } else {
        this.optionCustomerGroups2 = [];
      }
    },
    async handleSearchCustomerGroup3(query, id) {
      if (query !== "" && query.length >= 1) {
        let optionCustomerGroups = await this.fetchCustomerGroup({
          search: query,
          length: 10,
          company_id: this.$store.state.user.currentUser.company_id,
          level: 3,
        });
        if (optionCustomerGroups.data.records != null) {
          optionCustomerGroups.data.records.unshift({
            id: 0,
            name: "All",
          });
          this.optionCustomerGroups3 = optionCustomerGroups.data.records;
        } else {
          this.optionCustomerGroups3 = [];
        }
      } else {
        this.optionCustomerGroups3 = [];
      }
    },
    generateCode() {
      // YYYYMMDDHHmmss
      let date = new Date();
      let code =
        date.getFullYear() +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        ("0" + date.getDate()).slice(-2) +
        ("0" + date.getHours()).slice(-2) +
        ("0" + date.getMinutes()).slice(-2) +
        ("0" + date.getSeconds()).slice(-2) +
        Math.random().toString(36).substring(2, 6).toUpperCase();

      return code;
    },
  },
  async mounted() {
    if (this.$route.query.id != null) {
      await this.getData(this.$route.query.id);
    }
    this.getOptionTerritory();
    this.getOptionFlyers();

    let optionCustomerGroups1 = await this.fetchCustomerGroup({
      length: 10,
      company_id: this.$store.state.user.currentUser.company_id,
      level: 1,
    });
    optionCustomerGroups1.data.records.unshift({
      id: 0,
      name: "All",
      code: "All",
    });
    this.optionCustomerGroups1 = optionCustomerGroups1.data.records;

    let optionCustomerGroups2 = await this.fetchCustomerGroup({
      length: 10,
      company_id: this.$store.state.user.currentUser.company_id,
      level: 2,
    });
    optionCustomerGroups2.data.records.unshift({
      id: 0,
      name: "All",
      code: "All",
    });
    this.optionCustomerGroups2 = optionCustomerGroups2.data.records;

    let optionCustomerGroups3 = await this.fetchCustomerGroup({
      length: 10,
      company_id: this.$store.state.user.currentUser.company_id,
      level: 3,
    });
    optionCustomerGroups3.data.records.unshift({
      id: 0,
      name: "All",
      code: "All",
    });
    this.optionCustomerGroups3 = optionCustomerGroups3.data.records;
  },
  computed: {},
};
</script>
<style>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
